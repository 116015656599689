<template>
  <v-container>
    <v-app-bar app color="white" elevation="0">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>星星点灯 - 课堂</v-toolbar-title>
      
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small fab color="secondary" text v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menu_items" :key="index">
            <v-list-item-title>
              <v-btn small text @click="on_menu_item_clicked(item.id)"><v-icon>{{ item.icon }}</v-icon>{{ item.title }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>




      <v-btn fab text small color="red" @click="on_logout_clicked">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <!-- <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab text x-small>
            <v-icon large color="primary">mdi-account-circle</v-icon>
          </v-btn>
        </template>
<v-card>
  <v-toolbar dark color="primary">
    <v-btn icon dark @click="dialog = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>设置</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn dark text @click="dialog = false"> 保存 </v-btn>
    </v-toolbar-items>
  </v-toolbar>
  <v-list three-line subheader>
    <v-subheader>应用内配置</v-subheader>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>账号设置<v-btn small text fab><v-icon>mdi-open-in-new</v-icon></v-btn></v-list-item-title>

      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>科目</v-list-item-title>

        <v-list-item-subtitle>设置您的授课科目</v-list-item-subtitle>

        <v-select v-model="e6" :items="states" :menu-props="{ maxHeight: '400' }" multiple hide-details="auto" outlined
          dense></v-select>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>班级</v-list-item-title>
        <v-list-item-subtitle>设置您的任课班级</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-divider></v-divider>
  <v-list three-line subheader>
    <v-subheader>通用配置</v-subheader>
    <v-list-item>
      <v-list-item-action>
        <v-checkbox v-model="notifications"></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Notifications</v-list-item-title>
        <v-list-item-subtitle>Notify me about updates to apps or games that I
          downloaded</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-checkbox v-model="sound"></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Sound</v-list-item-title>
        <v-list-item-subtitle>Auto-update apps at any time. Data charges may
          apply</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-action>
        <v-checkbox v-model="widgets"></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Auto-add widgets</v-list-item-title>
        <v-list-item-subtitle>Automatically add home screen widgets</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-card>
</v-dialog> -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-card-title>星光榜
        <v-spacer></v-spacer>
        <v-btn fab text small @click="on_series_data_refresh_clicked">
          <v-icon>mdi-refresh</v-icon></v-btn>
      </v-card-title>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="item in series_groups" :key="item.name">
              <td><v-btn text :href="'/app/stars/group/' + item.id" :target="'__blank'">{{ item.name }}</v-btn></td>
              <td>
                <v-icon color="yellow darken-1">mdi-star</v-icon>
                x {{ item.scores }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-subtitle>（自 {{ series_time }} 开始统计）</v-card-subtitle>

    </v-navigation-drawer>

    <v-row>
      <v-col cols="12" xl="9" lg="10" md="11" sm="12" class="mx-auto">
        <v-row class="mt-2">
          <v-col class="mt-1">
            <v-card elevation="0" rounded="lg" class="mb-4" color="transparent">
              <v-card-text>
                <v-row>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field v-model="start_time" label="时间" dense readonly></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field v-model="subject_name" label="科目" dense readonly></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field v-model="class_name" label="班级" dense readonly></v-text-field>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
            <v-row class="mb-8">
              <template v-for="group in groups">
                <v-col :key="group.id">
                  <v-card class="pa-0" flat>
                    <v-card-title 
                      @mouseenter="group.isHovered = true"
                      @mouseleave="group.isHovered = false">
                      <div v-if="!group.isEditing" class="d-flex align-center">
                        {{ group.name }}
                        <v-btn 
                          v-if="group.isHovered"
                          icon 
                          x-small 
                          class="ml-1"
                          @click="startEdit(group)">
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                      
                      <div v-else class="d-flex align-center" style="gap: 8px">
                        <v-text-field
                          v-model="group.editedName"
                          dense
                          single-line
                          hide-details
                          autofocus
                          style="max-width: 120px"
                        ></v-text-field>
                        <v-btn
                          icon
                          x-small
                          color="success"
                          @click="saveEdit(group)"
                        >
                          <v-icon x-small>mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          x-small
                          color="error"
                          @click="cancelEdit(group)"
                        >
                          <v-icon x-small>mdi-close</v-icon>
                        </v-btn>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn icon small @click="on_minus_clicked(group.id)">
                        <v-icon small color="white">mdi-minus</v-icon>
                      </v-btn>
                      
                        <v-btn outlined icon small>{{ group.total_scores }}</v-btn>
                        <v-btn icon small @click="on_plus_clicked(group.id)">
                        <v-icon small>mdi-plus</v-icon>
                      </v-btn>
                      <!-- {{ group.total_scores }} -->
                      <!-- <v-btn text color="secondary">
                        {{ group.total_scores }}
                      </v-btn> -->
                    </v-card-title>
                    <v-card flat class="overflow-y-auto text-center" min-height="30" max-height="30">
                      <v-icon v-for="(item, index) in group.stars" :key="index" :color="item">mdi-star</v-icon>
                    </v-card>
                    <!-- <v-card-text>
                      <v-virtual-scroll
                        height="100"
                        item-height="20"
                        :items="group.stars"
                      >
                        <template v-slot:default="{ item }">
                          <v-list-item :key="item">
                            <v-icon :color="item">mdi-star</v-icon>
                          </v-list-item>
                        </template>
          </v-virtual-scroll>
          </v-card-text> -->
                    <!-- <v-card-actions>
                      <v-btn icon small @click="on_minus_clicked(group.id)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn icon small @click="on_plus_clicked(group.id)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-card-actions> -->
                  </v-card>
                </v-col>
                <v-responsive v-if="group.num % my_col_num === 0" :key="`width-${group.num}`"
                  width="100%"></v-responsive>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: [],

  data: () => ({
    drawer: null,

    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    e6: [],
    states: [
      "数学",
      "科学",
    ],

    start_time: "2024-01-01",
    subject_name: "科学",
    class_name: "三年二班",

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu_items: [
      { id: 1, title: "将此课设为 课堂模板", icon: "mdi-star-outline" },
      { id: 2, title: "将此课设为 星光榜统计起点", icon: "mdi-flag-outline" },
    ],

    my_row_num: 4,
    my_col_num: 4,
    groups: [
      // {
      //   num: 1,
      //   id: 1,
      //   name: "小组1",
      //   stars: [
      //     "red darken-1",
      //     "yellow darken-1",
      //   ],
      // },
    ],

    series_time: "?",
    series_groups: [
      // {
      //   name: "第二组",
      //   scores: 159,
      // },
      // {
      //   name: "第一组",
      //   scores: 237,
      // },
    ],
  }),

  methods: {
    on_logout_clicked() {
      axios.post("/backend/app/user/logout").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.$router.push({
            path: "/app/user/login/stars",
          });
        }
      });
    },
    on_series_data_refresh_clicked() {
      this.get_series(this.$route.params.lesson_id);
    },
    on_menu_item_clicked(item_id) {
      if (item_id == 1) {
        axios
          .post("/backend/app/stars/lesson/template/update", {
            lesson_id: this.$route.params.lesson_id
          });
      } else if (item_id == 2) {
        axios
          .post("/backend/app/stars/lesson/series/create", {
            lesson_id: this.$route.params.lesson_id
          });
      }
    },
    on_plus_clicked(group_id) {
      for (var i in this.groups) {
        var group = this.groups[i];
        if (group.id == group_id) {
          group.total_scores += 1;
          group.scores += 1;
          if (group.scores > 0) {
            group.stars.push("yellow darken-1");
          }
          this.update_group_scores(this.$route.params.lesson_id, group_id, group.scores);
        }
      }
    },
    on_minus_clicked(group_id) {
      for (var i in this.groups) {
        var group = this.groups[i];
        if (group.id == group_id) {
          group.total_scores -= 1;
          group.scores -= 1;
          group.stars.pop();
          this.update_group_scores(this.$route.params.lesson_id, group_id, group.scores);
        }
      }
    },
    update_group_scores(lesson_id, group_id, group_scores) {
      axios
        .post("/backend/app/stars/lesson/modify", {
          lesson_id: lesson_id,
          group_id: group_id,
          group_scores: group_scores
        });
    },

    startEdit(group) {
      group.originalName = group.name
      group.editedName = group.name
      group.isEditing = true
    },
    
    cancelEdit(group) {
      group.name = group.originalName
      group.isEditing = false
    },
    
    saveEdit(group) {
      group.isEditing = false
      axios.post("/backend/app/stars/group/update", {
        group_id: group.id,
        new_name: group.editedName
      }).then(() => {
        this.get_lesson(this.$route.params.lesson_id)
      })
    },

    get_series(lesson_id) {
      axios
        .post("/backend/app/stars/lesson/series/get", {
          lesson_id: lesson_id
        })
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            var data = response.data.data;
            this.series_time = data.start_time;
            this.series_groups.splice(0, this.series_groups.length);
            for (var i = 0; i < data.groups.length; i++) {
              var group = data.groups[i];
              this.series_groups.push({
                id: group.id,
                name: group.name,
                scores: group.scores,
              });
            }
          }
        });

    },

    get_lesson(lesson_id) {
      axios
        .post("/backend/app/stars/lesson/get", {
          lesson_id: lesson_id
        })
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            var data = response.data.data;
            this.start_time = data.start_time;
            this.subject_name = data.subject_name;
            this.class_name = data.class_name;

            this.my_row_num = data.rows;
            this.my_col_num = data.cols;
            this.groups.splice(0, this.groups.length);
            for (var i = 0; i < data.groups.length; i++) {
              var group = data.groups[i];
              var stars = [];
              for (var j = 0; j < group.scores; j++) {
                stars.push("yellow darken-1");
              }
              this.groups.push({
                num: i + 1,
                id: group.id,
                name: group.name,
                stars: stars, // 当前课堂的可视星星数组
                scores: group.scores, // 当前课堂的星星总数（可能是负数）
                total_scores: group.total_scores, // 课堂系列的星星总数（可能是负数）
                isHovered: false, // 添加此行
                isEditing: false, // 如果未定义也需添加
                editedName: "",     // 如果未定义也需添加
              });
            }
          }
        });
    }
  },

  mounted() {
    this.get_lesson(this.$route.params.lesson_id);
    this.get_series(this.$route.params.lesson_id);
  },

  computed: {},
};
</script>

<style scoped>
.post-content>>>img,
p,
span {
  max-width: 100%;
}

.post-content>>>pre {
  overflow: auto;
  background-color: whitesmoke;
  border-left: 6px solid grey;
  padding: 10px;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>
