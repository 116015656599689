<template>
  <v-container>

    
    <v-row class="mb-4 mt-8">
      <v-col>
        <v-btn color="primary" @click="showCreateDialog = true">
          <v-icon left>mdi-plus</v-icon>
          新建用户
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="users"
      :loading="loading"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item.last_login="{ item }">
        <span v-if="item.last_login">
          {{ formatDate(item.last_login.time) }}<br>
          <small class="grey--text">{{ item.last_login.agent }}</small>
        </span>
        <span v-else class="grey--text">从未登录</span>
      </template>
    </v-data-table>

    <v-dialog v-model="showCreateDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">创建新用户</v-card-title>
        <v-card-text>
          <v-form ref="createForm" @submit.prevent="createUser">
            <v-text-field
              v-model="newUser.email"
              label="邮箱"
              :rules="[v => !!v || '邮箱不能为空', v => /.+@.+\..+/.test(v) || '邮箱格式不正确']"
              required
            />
            <v-text-field
              v-model="newUser.password"
              label="密码"
              type="password"
              :rules="[v => !!v || '密码不能为空', v => v.length >= 6 || '密码至少6位']"
              required
            />
            <v-text-field
              v-model="newUser.display_name"
              label="显示名称"
              :rules="[v => !!v || '显示名称不能为空']"
              required
            />
            <v-combobox
              v-model="newUser.subjects"
              label="选择科目"
              :items="subjectOptions"
              multiple
              chips
              :rules="[v => !!v.length || '至少选择一个科目']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showCreateDialog = false">取消</v-btn>
          <v-btn color="primary" @click="createUser">创建</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      headers: [
        { text: '用户', value: 'display_name' },
        { text: '邮箱', value: 'email' },
        { text: '最近登录信息', value: 'last_login' },
      ],
      users: [],
      loading: false,
      showCreateDialog: false,
      newUser: {
        email: '',
        password: '',
        display_name: '',
        subjects: []
      },
      subjectOptions: [],
    }
  },
  created() {
    this.fetchSubjects();
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      this.loading = true;
      try {
        const response = await axios.get('/backend/app/users/all');
        if (response.data.status === 0) {
          this.users = response.data.data.users;
        } else {
          console.error('获取用户失败：', response.data.message);
        }
      } catch (error) {
        console.error('请求异常：', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchSubjects() {
      try {
        const response = await axios.get('/backend/app/stars/subjects/all');
        if (response.data.status === 0) {
          this.subjectOptions.splice(0, this.subjectOptions.length);
            var items = response.data.data.items;
            for (var index in items) {
              this.subjectOptions.push(items[index].name);
            }

        } else {
          console.error('获取用户失败：', response.data.message);
        }
      } catch (error) {
        console.error('请求异常：', error);
      }
    },

    formatDate(isoString) {
      return new Date(isoString).toLocaleString()
    },
    async createUser() {
      if (!this.$refs.createForm.validate()) return
      
      try {
        // RSA加密密码
        const encryptedPassword = this.newUser.password
        
        const response = await axios.post('/backend/app/user/create', {
          email: this.newUser.email,
          password: encryptedPassword,
          display_name: this.newUser.display_name,
          subjects: this.newUser.subjects
        })

        if (response.data.status === 0) {
          await this.fetchUsers() // 刷新用户列表
          this.showCreateDialog = false
          this.newUser = { email: '', password: '', display_name: '', subjects: [] }
        }
      } catch (error) {
        console.error('创建用户异常:', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  margin-top: 20px;
}

.v-card__actions {
  padding: 16px;
}
</style>
