<template>
  <v-container>
    <v-app-bar app color="white" elevation="0">

      <v-toolbar-title>星星点灯 - 创建课堂</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn fab text small color="red" @click="on_logout_clicked">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

    </v-app-bar>

    <v-col>
      <v-card elevation="10" width="500px" class="mx-auto">
        <v-card-text>
          <v-row>
            <v-col>
              <v-select label="科目" v-model="selected_subject" :items="subjects" :menu-props="{ maxHeight: '400' }"
                outlined dense></v-select>
            </v-col>
            <v-col>

              <v-combobox v-model="selected_class" :items="my_classes" item-text="name" :hide-no-data="!search"
                :search-input.sync="search" hide-selected label="班级" outlined dense>

                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click.stop="deleteClass(item)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>

                <template v-slot:no-data>
                  <v-list-item>
                    <span class="subheading">按下回车创建选项</span>
                    <v-chip :color="`blue`" label outlined>
                      {{ search }}
                    </v-chip>
                  </v-list-item>
                </template>

              </v-combobox>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-checkbox v-if="enable_template_option" v-model="use_latest_template" :label="`使用预设的课堂模板`"></v-checkbox>
          <v-sheet v-if="!use_latest_template">
            <v-divider class="my-6"></v-divider>
            <p>设置分组数量：</p>
            <v-row>
              <v-col>
                <v-select dense :items="my_rows" v-model="selected_row_num" label="行" outlined></v-select>
              </v-col>
              <v-col>
                <v-select dense :items="my_cols" v-model="selected_col_num" label="列" outlined></v-select>
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <p>设置排列顺序：</p>

            <v-radio-group v-model="selected_order" dense row>
              <v-row>
                <v-col>
                  <v-radio value="0">
                    <template v-slot:label>
                      <table>
                        <caption>z 字型</caption>
                        <tr v-for="i of preview_scale" :key="i">
                          <td v-for="j of preview_scale" :key="j">
                            <v-btn disabled x-small outlined>{{ preview_z_groups[(i - 1) * preview_scale + j - 1]
                              }}</v-btn>
                          </td>
                        </tr>
                      </table>
                    </template>
                  </v-radio>
                </v-col>
                <v-col>
                  <v-radio value="1">
                    <template v-slot:label>
                      <table>
                        <caption>z 字型镜像</caption>
                        <tr v-for="i of preview_scale" :key="i">
                          <td v-for="j of preview_scale" :key="j">
                            <v-btn disabled x-small outlined>{{ preview_mirror_z_groups[(i - 1) * preview_scale + j - 1]
                              }}</v-btn>
                          </td>
                        </tr>
                      </table>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio value="2">
                    <template v-slot:label>
                      <table>
                        <caption>n 字型</caption>
                        <tr v-for="i of preview_scale" :key="i">
                          <td v-for="j of preview_scale" :key="j">
                            <v-btn disabled x-small outlined>{{ preview_n_groups[(i - 1) * preview_scale + j - 1]
                              }}</v-btn>
                          </td>
                        </tr>
                      </table>
                    </template>
                  </v-radio>
                </v-col>
                <v-col>
                  <v-radio value="3">
                    <template v-slot:label>
                      <table>
                        <caption>n 字型镜像</caption>
                        <tr v-for="i of preview_scale" :key="i">
                          <td v-for="j of preview_scale" :key="j">
                            <v-btn disabled x-small outlined>{{ preview_mirror_n_groups[(i - 1) * preview_scale + j - 1]
                              }}</v-btn>
                          </td>
                        </tr>
                      </table>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>

            <v-divider class="my-6"></v-divider>
          </v-sheet>
          <p>布局预览：</p>
          <v-row class="mx-auto my-auto justify-center">
            <table>
              <tr>
                <td>
                  <v-btn disabled x-small outlined>讲台</v-btn>
                </td>
              </tr>
            </table>
          </v-row>
          <v-row class="mx-auto my-auto justify-center">
            <table>
              <tr v-for="i of selected_row_num" :key="i">
                <td v-for="j of selected_col_num" :key="j">
                  <v-btn disabled x-small outlined>{{ my_groups[(i - 1) * selected_col_num + j - 1] }}</v-btn>
                </td>
              </tr>
            </table>
          </v-row>
          <v-divider class="my-6"></v-divider>
          <v-row>
            <v-col>
              <v-btn class="my-2" block dark color="red" @click="on_click_start_lesson">开始新课</v-btn>
            </v-col>
            <v-col>
              <v-btn class="my-2" block color="primary"
                @click="on_click_restore_lesson"><v-icon>mdi-restore</v-icon>回到上一节课</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,

      // 科目选择
      selected_subject: null,
      // selected_subject: '科学',
      subjects: [],
      // subjects: ['语文', '科学'],

      // 班级选择
      selected_class: null,
      my_classes: [
        {
          id: 1,
          name: "hello",
        },
        {
          id: 2,
          name: "world",
        },
      ],
      search: null,

      // 排列选择
      selected_order: "0",
      preview_scale: 2, // 缩略图的行/列数
      preview_z_groups: [1, 2, 3, 4],
      preview_mirror_z_groups: [2, 1, 4, 3],
      preview_n_groups: [1, 3, 2, 4],
      preview_mirror_n_groups: [3, 1, 4, 2],

      // 模板
      enable_template_option: false,
      use_latest_template: false,
      template_rows: 2,
      template_cols: 2,
      template_order: "0",

      my_rows: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      my_cols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      selected_row_num: 3,
      selected_col_num: 4,
      my_groups: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  },
  methods: {
    deleteClass(cls) {
      if (!confirm(`确定要删除班级【${cls.name}】吗？`)) return;
      
      axios.post(`/backend/app/stars/class/delete`, {
        class_id: cls.id
      })
      .then(response => {
        if (response.data.status === 0) {
          this.my_classes = this.my_classes.filter(c => c.id !== cls.id);
          if (this.selected_class?.id === cls.id) {
            this.selected_class = null;
          }
        }
      })
      .catch(error => {
        console.error('删除班级失败:', error);
      });
    },

    on_logout_clicked() {
      axios.post("/backend/app/user/logout").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.$router.push({
            path: "/app/user/login/stars",
          });
        }
      });
    },
    on_click_start_lesson() {
      axios
        .post("/backend/app/stars/lesson/create", {
          subject: this.selected_subject,
          class: this.selected_class,
          rows: this.selected_row_num,
          cols: this.selected_col_num,
          order: this.selected_order,
          use_latest_template: this.use_latest_template,
        })
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            var lesson_id = response.data.data.lesson_id;
            // 跳转到课堂页
            this.$router.push({
              path: "/app/stars/lesson/" + lesson_id,
            });
          }
        });
    },

    on_click_restore_lesson() {
      axios
        .post("/backend/app/stars/lesson/restore", {
          subject: this.selected_subject,
          class: this.selected_class,
        })
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            var lesson_id = response.data.data.lesson_id;
            // 跳转到课堂页
            this.$router.push({
              path: "/app/stars/lesson/" + lesson_id,
            });
          }
        });
    },

    get_subjects() {
      axios.get("/backend/app/stars/subjects/select")
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            this.subjects.splice(0, this.subjects.length);
            var items = response.data.data.items;
            for (var index in items) {
              this.subjects.push(items[index].name);
            }
            if (this.subjects.length > 0) {
              this.selected_subject = this.subjects[0];
            }
          }
        })
    },

    get_classes() {
      axios.get("/backend/app/stars/classes/select")
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            this.my_classes.splice(0, this.my_classes.length);
            var items = response.data.data.items;
            for (var index in items) {
              this.my_classes.push(items[index]);
            }
            if (this.my_classes.length > 0) {
              this.selected_class = this.my_classes[0];
            }
          }
        })
    },

    get_lesson_template() {
      this.selected_row_num = this.template_rows;
      this.selected_col_num = this.template_cols;
      this.enable_template_option = false;
      this.use_latest_template = false;

      axios
        .post("/backend/app/stars/lesson/template/get", {
          subject: this.selected_subject,
          class: this.selected_class
        })
        .then((response) => {
          console.log(response.data);
          var status = response.data.status;
          if (status == 0) {
            this.template_rows = response.data.data.rows;
            this.template_cols = response.data.data.cols;
            this.template_order = String(response.data.data.order);

            this.selected_row_num = this.template_rows;
            this.selected_col_num = this.template_cols;
            this.selected_order = this.template_order;

            this.enable_template_option = true;
            this.use_latest_template = true;
          } else {
            this.enable_template_option = false; // 不显示课堂模板开关
            this.use_latest_template = false;
          }
        });
    },

    init_groups(group_array, rows, cols, order) {
      if (order == 0) {
        this.init_z_groups(group_array, rows, cols);
      } else if (order == 1) {
        this.init_mirror_z_groups(group_array, rows, cols);
      } else if (order == 2) {
        this.init_n_groups(group_array, rows, cols);
      } else if (order == 3) {
        this.init_mirror_n_groups(group_array, rows, cols);
      }
    },

    // 在group_array上初始化z字型小组布局
    init_z_groups(group_array, rows, cols) {
      var group_id = 1;
      for (var i = 0; i < rows; i++) {
        for (var j = 0; j < cols; j++) {
          var index = i * cols + j;
          group_array[index] = group_id;
          group_id++;
        }
      }
    },

    init_mirror_z_groups(group_array, rows, cols) {
      var group_id = 1;
      for (var i = 0; i < rows; i++) {
        for (var j = cols - 1; j >= 0; j--) {
          var index = i * cols + j;
          group_array[index] = group_id;
          group_id++;
        }
      }
    },

    init_n_groups(group_array, rows, cols) {
      var group_id = 1;
      for (var j = 0; j < cols; j++) {
        for (var i = 0; i < rows; i++) {
          var index = i * cols + j;
          group_array[index] = group_id;
          group_id++;
        }
      }
    },

    init_mirror_n_groups(group_array, rows, cols) {
      var group_id = 1;
      for (var j = cols - 1; j >= 0; j--) {
        for (var i = 0; i < rows; i++) {
          var index = i * cols + j;
          group_array[index] = group_id;
          group_id++;
        }
      }
    },

  },
  watch: {
    selected_row_num(val, prev) {
      if (val != prev) {
        this.init_groups(this.my_groups, val, this.selected_col_num, this.selected_order);
      }
    },
    selected_col_num(val, prev) {
      if (val != prev) {
        this.init_groups(this.my_groups, this.selected_row_num, val, this.selected_order);
      }
    },
    selected_order(val, prev) {
      if (val != prev) {
        // 重新计算小组布局数组的取值
        this.init_groups(this.my_groups, this.selected_row_num, this.selected_col_num, val);
      }
    },
    selected_subject(val, prev) {
      if (val != prev) {
        if (this.selected_class != null) {
          console.log("changed.");
          this.get_lesson_template();
        }
      } else {
        console.log("no changes.");
      }
    },
    selected_class(val, prev) {
      if (val != prev) {
        if (this.selected_subject != null) {
          console.log("changed.");
          this.get_lesson_template();
        }
      } else {
        console.log("no changes.");
      }
    },

    use_latest_template(val) {
      if (val == true) {
        this.selected_col_num = this.template_cols;
        this.selected_row_num = this.template_rows;
      }
    }
  },
  mounted() {
    this.get_subjects();
    this.get_classes();
  }
};
</script>
